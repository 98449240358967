import React from 'react'

import { account } from '../../store.config'
import { NpmCampaignsPage } from '../local_modules'

const Campaigns = () => {
  const segment: { [key: string]: string } = {
    boa: 'traditional',
    superpao: 'traditional',
    compremais: 'economic',
    domolivio: 'premium',
    avenidab2c: 'traditional',
  }

  return <NpmCampaignsPage segment={segment[account]} />
}

export default Campaigns
